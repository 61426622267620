@import '../../utils/custom-additional-variables.scss';
.editor-header{
    z-index: 10000;
    background-color: $base-light-accent;
    box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.12), 0 1px 2px rgba($base-black, alpha($base-black) * 0.24) !important;
    margin-bottom: 2px;
}

.editor-section-width{
    padding-top: 12px;
    .screen-ex-large & {
        width: calc(100vw - 600px);
      }
      .screen-large & {
        width: calc(100vw - 300px);
      }
}

.editor-section-height{
  padding-top: 12px;
  height: calc(100vh - 60px);
}

.editor-section-height-sm{
  padding-top: 12px;
  height: calc(100vh - 140px) !important;
}