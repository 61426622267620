@import "../../utils/custom-additional-variables.scss";
@import "../../dx-styles.scss";


.accept-offer-section {


  .accept-offer-card {
    padding: 10px 0px;


    box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
    background-color: $base-light-accent;
  }

  .offerLetter-iframe {
    height: calc(100vh - 160px);
    padding-bottom: 6px;
  }

  .offerLetter-iframe-sm {
    height: calc(100vh - 300px);
  }
  .reject-offer-btn {
    background: $base-danger !important;
    color: $base-white;
    border-radius: 8px !important;
    font-weight: 500;
  
    .dx-button-content {
      padding: 6px;
    }
  }

}