@import '../../themes/generated/variables.base.scss';

    .checkbox_label {
        font-size: small;
    }
    .registartion-title{
        color: $base-accent;
    }
    .email_link{
        color: $base-accent;
    }
    .loaderCart-height{
        height: 250px;
    }
    .indicator{
        margin-top: 35%;
    }
