@import "../../utils/custom-additional-variables.scss";
@import "../../dx-styles.scss";

.interviewer-badge {
  background-color: $base-danger;
  font-size: 12px;
  color: $base-white;
  padding: 0px 4px 2px 4px;
  border-radius: 3px;
  vertical-align: super;
}

.assessment-pipe {
  color: $base-gray !important;
  font-weight: 100 !important;
}

.left-user-img {
  background-color: $base-light-accent;
  border-radius: 100%;
  padding: 4px;
  margin-top: 6px;
}

.assessment-section {
  background-color: #e7f0f7;
  padding: 10px 32px;
  min-height: 100vh;
  height: auto !important;

  .tab-panel-item {
    min-height: 75vh;
    height: auto;
  }

  .age-text {
    color: $base-gray;
    @extend .font-medium-14;
  }

  .short-name {
    @extend .font-medium-14;
    background-color: $base-soft-pink;
    border-radius: 100%;
    padding: 4px;
    margin-right: 8px;
  }

  .short-name-ar {
    @extend .font-medium-14;
    background-color: $base-soft-blue;
    border-radius: 100%;
    padding: 4px;
    margin-right: 8px;
  }

  .dx-assesment-custom-icon {
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    background-size: contain !important;
    background-position: 0 0;
    padding: 0;
    font-size: 17px;
    text-align: center;
    line-height: 17px;
  }

  .dx-assesment-custom-icon-lg {
    display: inline-block;
    vertical-align: middle;
    width: 22px;
    height: 22px;
    background-size: contain !important;
    background-position: 0 0;
    padding: 0;
    font-size: 22px;
    text-align: center;
    line-height: 22px;
  }

  .dx-assesment-custom-icon-skype-lg {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background-size: contain !important;
    background-position: 0 0;
    padding: 0;
    font-size: 20px;
    text-align: center;
    line-height: 20px;
  }

  .assessment-card {
    background-color: $base-white;
    box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.056), 0 1px 2px rgba($base-black, alpha($base-black) * 0.09);
    padding: 10px;
    border-radius: 4px;
  }


  .italic-text {
    @extend .font-regular-13;
    color: $base-gray;
    font-style: italic;
  }

  .assessment-details-tab {
    .candidate-details-top {
      @extend .font-medium-14;
    }

    .dx-tabs {
      display: block;
      box-shadow: none !important;
      background-color: transparent !important;
    }

    .dx-tab {
      box-shadow: none !important;
      background-color: transparent !important;
    }

    .dx-tab.dx-tab-selected {
      box-shadow: none !important;
      background-color: $base-light-accent !important;
      border-bottom: 2px solid $base-blue;
    }

    .dx-tabpanel .dx-multiview-wrapper .dx-state-focused {
      border: none !important;
      box-shadow: none !important;
    }

    .dx-multiview-wrapper {
      border-top: 1px solid $base-muted-gray !important;
    }

    .dx-tabpanel .dx-multiview-wrapper {
      border: none;
    }

    .dx-tabpanel>.dx-tabpanel-tabs .dx-tab {
      padding: 6px !important;
    }
  }

  .assessment-both-card {
    height: 100% !important;
    padding-bottom: 10px !important;
  }

  .custom-card-grid {
    box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
    border-radius: 10px;
    border: 1px solid $base-light-accent;
    background-color: $base-white !important;
  }

  .TechSkillDatagrid {
    .dx-datagrid-header-panel {
      border-bottom: none;
    }

    .dx-datagrid .dx-header-filter-empty {
      color: $base-white;
    }

    .dx-datagrid-search-panel {
      margin-left: 8px;
    }

    .dx-datagrid {
      border-radius: 10px;
      background-color: $base-white !important;
    }

    .dx-header-row {
      background-color: $base-accent !important;
      color: $base-white;
      .dx-col-fixed{
        background-color: $base-accent !important;
      }
    }


    .dx-row .dx-data-row .dx-row-lines{
        background-color: #fff;
    }

    .dx-datagrid-borders>.dx-datagrid-headers,
    .dx-datagrid-borders>.dx-datagrid-rowsview,
    .dx-datagrid-borders>.dx-datagrid-total-footer {
      border-radius: 0px 0px 6px 6px;
    }

    .dx-datagrid-headers {
      border-radius: 6px 6px 0px 0px !important;

      .dx-datagrid-content {
        border-radius: 6px 6px 0px 0px !important;
      }
    }

    .dx-datagrid-table {
      border-collapse: unset !important;
      border-spacing: 0;
      margin: 0;
    }
  }

  .SkillDataGrid{
      /* start Set a consistent row height for the DataGrid to avoid shaking effect */
       .dx-datagrid-rowsview .dx-row {
             height: 40px !important; 
             white-space: nowrap !important;
             overflow: hidden !important;
        }
      /* end Set a consistent row height for the DataGrid to avoid shaking effect */
  }

  .assessment-html-editor-section {
    box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
    border: 1px solid $base-light-accent;
    border-radius: 4px;

    .dx-htmleditor {
      border: none !important;
    }
  }

  .submit-button {
    .dx-button-content {
      border: none !important;
    }
  }

  .grid-add-btn {
    .dx-button-content {
      padding: 3px;
    }
  }

  .dx-datagrid-header-panel .dx-toolbar {
    margin-bottom: 0;
    padding: 0px 3px;
  }

  .other-assessment-header {
    background-color: $base-light-accent;
    padding: 6px;
    border-radius: 8px 8px 0px 0px;
    @extend .font-medium-16;
  }

  .other-assessment-card {
    margin: 5px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.056), 0 1px 2px rgba($base-black, alpha($base-black) * 0.09) !important;
  }

  .interviews-tag {
    padding: 4px;
    height: 20px;
    font-size: 11px;
    width: 20px;
    font-weight: 500;
    border-radius: 100%;
    color: $base-white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .interviews-tag-blue {
    background: $base-deep-purple;
  }

  .interviews-tag-red {
    background: $base-steel-blue;
  }

  .interviews-tag-green {
    background: rgba($base-deep-forest-green, alpha($base-deep-forest-green) * 0.929);
  }

  .observation_text {
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.NameSection {
  max-width: 250px !important;
}

.EmailSection {
  max-width: 150px !important;
}

.Avg-Rating-Grid {
  height: 300px !important;
}