@import '../../dx-styles.scss';
@import '../../utils/custom-additional-variables.scss';

.CandidatePictureSection {
    width: 150px;

    #dropzone-external {
        width: 150px;
        height: 150px;
        border-radius: 4px;
        background-color: $base-picture-bg;
        border-width: 1px;
        border-style: solid;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #dropzone-external>* {
        pointer-events: none;
    }

    #dropzone-external.dropzone-active {
        border-style: solid;
    }

    #dropzone-image {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 4px !important;
        object-fit: contain !important;
    }

    #dropzone-text {
        @extend .font-regular-13;


        span {
            font-weight: 100;
            //opacity: 0.5;
        }
    }

    #upload-progress {
        display: flex;
        margin-top: 10px;
    }

    .maxImageSizeSpan {
        font-size: 10px;
        color: $base-danger;
    }

    .maxImageSizeValidation {
        color: $base-danger;
    }


}

.popupCandidatePictureSection {

    #dropzone-external-popup {
        width: 150px;
        height: 150px;
        border-radius: 4px;
        background-color: $base-picture-bg;
        border-width: 1px;
        border-style: solid;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #dropzone-external-popup>* {
        pointer-events: none;
    }

    #dropzone-external-popup.dropzone-active {
        border-style: solid;
    }

    #dropzone-image {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 4px !important;
    }

    #dropzone-text {
        @extend .font-regular-13;


        span {
            font-weight: 100;
            //opacity: 0.5;
        }
    }

    #upload-progress {
        display: flex;
        margin-top: 10px;
    }

    .maxImageSizeSpan {
        font-size: 10px;
        color: $base-danger;
    }

    .maxImageSizeValidation {
        color: $base-danger;
    }

}

.uploadResume {
    height: 55.5px;

    .dx-button {
        border-radius: 0 !important;
        border-left: 0 !important;
        border-bottom: 0 !important;
        border-top: 0 !important;
        padding-right: 10px !important;
        padding-top: 2px !important;
    }

    .dx-fileuploader-input-wrapper {
        padding: 5px 0px;
    }

    // .dx-fileuploader-input-label{
    //    display:unset;      
    // }
    .dx-fileuploader-wrapper {
        width: 85px !important;
    }

    .dx-button-has-text .dx-button-content {
        padding: 8px;
    }

    .dx-fileuploader-wrapper {
        padding: 0 !important;
    }

    .dx-button-text {
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .spin_down_icon {
        .dx-icon-spindown {
            font-size: 25px !important;
        }
    }
}

.resumeUpload {
    height: 60px;

    .dx-fileuploader-content {
        position: relative;
    }

    .dx-fileuploader-files-container {
        padding: 12px 3px 0;
        position: absolute;
        top: 0;
        right: 0;
        left: 100px !important;
        width: auto;
    }

    .dx-fileuploader-file {
        display: unset !important;
    }
}

.filename_Section {
    border: 0px solid $base-black !important;
    background-color: $base-light-sky-blue;
    border-radius: 50px !important;
    padding: 0px 0px 0px 18px !important;

    .dx-button-has-icon .dx-icon {
        color: $base-black;
        font-size: 10px !important;
    }
}

.uploadResume,
.uploadResumePopup {
    .dx-fileuploader-files-container {
        padding-top: 0px !important;
    }

}

.resume-clear-btn-popup {
    position: absolute;
    bottom: 2px;
    left: -30px;
}

.resume-clear-btn {
    // position: absolute;
    bottom: 2px;
    left: -20px;
}

.candidate-picture-delete-icon {
    .dx-button-content {
        padding: 6px;
    }
}

.candidate-picture-sm {
    width: 160px;
}



.image-Skeleton-Candidateprofile {
    height: 150px;
    width: 150px;
}

.fileName {
    padding-top: 4px !important;
    padding-left: 8px;
    cursor: pointer;
}

.CandidatePicture {
    padding-top: 22px !important;
}

.image-hover-section .profile-image {
    opacity: 1;
    transition: 0.5s;
}

.image-hover-section:hover .profile-image {
    opacity: 0.3 !important;
}

.image-hover-section .content-on-image {
    opacity: 0;
    transition: 0.5s;
    z-index: 100;

    transform: translate(2%, 1.5%);

    .screen-small & {
        opacity: 1 !important;
    }

    .screen-ex-small & {
        opacity: 1 !important;
    }

    .screen-xx-small & {
        opacity: 1 !important;
    }

    .screen-x-small & {
        opacity: 1 !important;
    }
}

.image-hover-section:hover .content-on-image {
    opacity: 1 !important;
}

.UploadResume_Box:hover {
    border-color: $base-accent !important;
}


.candidate-custom-card {
    box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
    border-radius: 4px;
    background-color: $base-light-accent;
    min-width: 225px !important;
    min-height: 70px !important;
}

.candidate-secondary-custom-card {
    box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
    border-radius: 4px;
    background-color: $base-light-accent;
    min-width: 200px !important;
    min-height: 70px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.italic-text {
    @extend .font-regular-13;
    color: $base-gray;
    font-style: italic;
}

#fileAttachmentManager {
    .dx-filemanager-items-panel {
        .dx-widget.dx-filemanager-breadcrumbs {
            display: none !important;
        }
    }

    .dx-drawer-panel-content {
        display: none !important;
    }

    .dx-filemanager-details-item-name {
        text-decoration: underline;
    }
}

.rename_popup_header {
    background-color: $base-light-accent;

    .dx-toolbar {
        background-color: $base-light-accent;
    }

    .remove-icon {
        .dx-button-content {
            padding: 2px !important;
        }

        .dx-icon {
            font-size: 18px !important;
        }
    }

}

.ReactModal__Overlay {
    z-index: 2000 !important;
}

.video-preview-popup {
    .dx-toolbar-items-container {
        background: #262626 0% 0% no-repeat padding-box !important;
        border: none !important;
        color: $base-white;

        .dx-item-content {
            font-size: 16px !important;
        }

        .dx-button-mode-text .dx-icon {
            color: $base-white;
            opacity: 0.7;
            font-size: 20px;
        }
    }

    ;

    .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable {
        background-color: rgba($base-black, alpha($base-black) * 0.85);
        border: none !important;
        border-radius: 0px !important;
    }

    ;
}

.download-btn {
    background: none;
    color: $base-white;
    margin: 0 10px;
}

// hiring pipeline progress bar

.candidate_progress_circle_completed {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: $base-white;
    background-color: $base-lime-green;
}

.hover-container {
    position: relative;
}

.hover-content {
    display: none;
}

.hover-container:hover .hover-content {
    display: block;
}

.CommentMessage {
    max-width: 100%;
    overflow: hidden;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.innerHTMLContent span {
    background-color: transparent !important;
}

.decription-container {
    background-color: $base-light-accent;
}

.Summary-arrowup-icon {
    font-size: 28px;
    color: $base-dark-accent;
    font-weight: 100 !important;
}

.Date-pipline-Container-1 {
    background-color: $base-dark-accent !important;
    color: white !important;
    padding: 2px 5px 2px 5px !important;
    border-radius: 30px !important;

}

.CandidateFileManger .dx-filemanager {
    height: 350px !important;

    @media (min-height : 600px) {
        height: 400px !important;
    }
}

.NoDescription-Container {
    height: 15px !important;
}

.ViewApplicationHistory-Buttton {
    height: 23px !important;
}

// CSS start to truncate schedule title in datagrid toolbar 

.ScheduleDataGrid {
    .dx-toolbar-before {
        display: contents !important;
        width: calc(100% - 230px) !important;
        overflow: hidden !important;
    }

    .dx-toolbar-item {
        display: inline-block !important;
        max-width: calc(100% - 260px) !important;
    }
}

// CSS end to truncate schedule title in datagrid toolbar

.more-infor-btn {
    text-decoration: underline;

    .dx-button-content {
        padding: 0% !important;
    }
}

.label-text {
    @extend .font-regular-12;
    font-style: italic;
    color: #6c6c6c;
}

/* Timeline styles */

.timeline {
    position: relative;
    margin-left: 20px;
    padding-left: 20px;
}

.timeline-dot {
    position: absolute;
    left: -10px;
    top: 4px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
}

.timeline-connector {
    position: absolute;
    left: -5px;
    top: 16px;
    width: 2px;
    height: 100%;
}

.timeline-color-1 {
    background-color: $base-dark-accent !important;
}

.timeline-color-2 {
    background-color: $base-border-grey !important;
}

.timeline-color-3 {
    background-color: $base-border-lightred !important;
}

.timeline-color-4 {
    background-color: $base-deep-purple !important;
}

.timeline-color-5 {
    background-color: $base-border-green !important;
}

.ObseravationColumn{
    width: calc(100% - 85px)!important;
}

.Candidate-Detail-section-profilePage{
    @media (min-width: 1400px) {
        max-width: calc(100% - 800px);
    }
}