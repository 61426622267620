@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss";

.company-wizard-left-section{
    background-color: $base-accent;
    // height: 525px !important;
}

.company-wizard-left-section-sm{
    background-color: $base-accent; 
    // height: 570px !important;
}
.company-wizard-right-section-sm{
  // height: 570px !important;
}
@media (min-width: 877px) and (max-width: 960.99px) {
  .company-wizard-right-section-sm {
    // height: 530px !important;
  }
  .company-wizard-left-section-sm{
    background-color: $base-accent; 
    // height: 530px !important;
}
}
.company-wizard-right-section{
  // height: 525px !important;
}

.wizardPopup{
    .dx-popup-content{
        padding: 0px !important;
    }
}

.wizard-tab-panel{
    .dx-tabpanel-tabs{
        display: none !important;
    }

    .dx-multiview-wrapper {
        border: none !important;
    }
}

.BackButton {
    background: $base-blue 0% 0% no-repeat padding-box !important;
  }
  
.line{
    color: $base-blue;
}
@media (min-width: 768px) and (max-width: 959.99px) {
  .line {
      font-size: smaller !important;
  }
}
.circle_button{
    font-size: medium;
    background-color:$base-accent;
    color: $base-white;
    border: 2px solid $base-white;
}
.circle_button2{
    font-size: medium;
    background-color:$base-accent;
    color: $base-dark-yellow !important;
    border: 3px solid $base-dark-yellow !important;
}

.circle_button3{
    font-size: medium;
    background-color:$base-accent;
    color: $base-light-gray !important;
    border: 3px solid $base-light-gray !important;
}

.dx-button-has-text .dx-icon {
    margin-right: 2px;
}
.number_button{
    height: 35px;
    width: 35px;
    line-height: 35px;
}
.progress_line{
  margin:18px 0px;
}
.progress_line_background{
    background-color:$base-white ;
}
.progress_line_background2{
    background-color:$base-light-gray ;
}
.Back__button{
    background-color: transparent !important;
}

.registration_CheckBox{
  .dx-invalid .dx-checkbox-container .dx-checkbox-icon{
    border:2px solid $base-danger !important;
    border-radius: 3px;
  }
}

.validatingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  
    .main-container {
      background-color: $base-secondary-accent;
      height: 500px;
      width: 500px;
  
      .screen-x-small & {
        width: 500px;
        height: 500px;
      }
  
      .screen-xx-small & {
        width: 500px;
        height: 500px;
      }
  
      .screen-ex-small & {
        width: 300px;
        height: 300px;
      }
  
      .screen-large & {
        width: 500px;
        height: 500px;
      }
  
      .screen-ex-large & {
        width: 500px;
        height: 500px;
      }
    }
  
    .validating-text {
      color: $base-dark-accent;
    }
  
    .Loading-cart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      height: 500px;
      width: 500px;
  
      .screen-ex-small & {
        width: 300px;
        height: 300px;
      }
    }
  }
  .Next-button{
    padding-top: 17.5px !important;
  }
  .finish-button{
    padding-top: 5.5px !important;
  }



