@import '../../utils/custom-additional-variables.scss';
.container {
  .main-container {
     background-color: $base-secondary-accent;

    .screen-x-small & {
      width: 100%;
    }
    .screen-xx-small & {
      width: 100%;
    }
    .screen-ex-small & {
      width: 100%;
    }
    .screen-large & {
      width: 70%;
    }
    .screen-ex-large & {
      width: 70%;
    }
  }

  .design_image {
    width: 100%;
  }

  .Logo-image {
    width: 150px;
  }
  .Logo-image2 {
    width: 150px;
  }


  .sub-heading {
    color: $base-navy-blue !important;
    font-size: 15.5px;
  }
}