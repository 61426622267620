@import "../../themes/generated/variables.base.scss";
@import '../../dx-styles.scss';
@import '../../utils/custom-additional-variables.scss';

.resume-upload-section {
    width: 100%;

    #resume-dropzone-external {
        height: 140px;
        border-radius: 4px;
        border-width: 1px;
        border-style: dashed;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $base-bg-light;
    }

    #resume-dropzone-external.dropzone-active {
        // border-color: #007bff;
        // background-color: #e7f1ff;
        border-style: dotted;
    }

    #dropzone-image {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        border-radius: 4px !important;
    }

    .uploaded-file-preview {
        display: flex;
        align-items: center;
    }

    .file-icon {
        width: 50px;
        height: 50px;
    }

    .upload-btn {
        background-color: transparent;
        border-color: $base-gray;
        color: $base-gray;
        .dx-icon {
            color: $base-gray;
        }
    }
}