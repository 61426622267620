@import "../../themes/generated/variables.base.scss";
@import "../../utils//custom-additional-variables.scss";

.login-form {
  
  .forgot-password-button .dx-button-content .dx-button-text{
    color: $base-dark-accent;
    font-size: medium;
    .screen-x-small & {
      font-size:small;
    }
    .screen-xx-small & {
      font-size:small;
    }
    .screen-ex-small & {
      font-size:small;
    }
  }
 
}
.loaderCart-height{
  height: 300px;
}
.indicator{
  margin-top: 40%;
}