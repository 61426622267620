@import "../../themes/generated/variables.additional.scss";
@import '../../utils/custom-additional-variables.scss';
@import '../../dx-styles.scss';



.SwitchBtn {
  .YesSwitch {
    .dx-switch-handle::before {
      background-color: $base-white !important;
      border-radius: 100% !important;
      width: 14px;
      height: 14px;
    }

    .dx-switch-on {
      width: 49%;

    }

    .dx-switch-container {
      background: transparent linear-gradient(90deg, #00AB66 0%, #6ba292 100%) 0% 0% no-repeat padding-box;
      border-radius: 30px !important;
      border: none !important;
      height: 18px;
      width: 35px;
    }
  }

  .NoSwitch {
    .dx-switch-handle::before {
      background-color: $base-white !important;
      border-radius: 100% !important;
      width: 14px;
      height: 14px;
    }

    .dx-switch-container {
      background: transparent linear-gradient(90deg, #dd614a 0%, #dd614a 100%) 0% 0% no-repeat padding-box;
      border-radius: 30px !important;
      border: none !important;
      height: 18px;
      width: 35px;
    }
  }

  .dx-switch.dx-state-focused .dx-switch-container {
    border-color: darkgrey !important;
  }

  .dx-switch.dx-state-hover .dx-switch-container {
    border-color: darkgrey !important;
  }
}

.JobProfile__title {
  color: $base-accent;
}



.top-card-caption {
  @extend .font-regular-12;
  font-style: italic;
  color: $base-gray;
}

.secondary-card {
  cursor: pointer;
}

.secondary-card-caption {
  @extend .font-semiBold-18;
}

.custom-card {
  box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
  border-radius: 4px;
  // border: 1px solid $base-light-accent;
  background-color: $base-light-accent;
  min-width: 250px !important;
}

.custom-card-two {
  box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
  border-radius: 4px;
  border: 1px solid $base-light-accent;
  background-color: $base-light-accent;
  // min-width: 220px !important ;
  // height: 120px !important;
  // height: 150px !important;
}

.custom-card-secondary {
  @extend .font-regular-13;
  box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
  border-radius: 4px;
  border: 1px solid $base-light-accent;
  background-color: $base-light-accent;
}

.custom-card-lg-screen{
  width: 130px;
  min-width: 130px !important;
}

.selected-card {
  background-color: $base-light-gray;
}

.custom-card-grid {
  box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
  border-radius: 4px;
  border: 1px solid $base-light-accent;
  background-color: $base-light-accent;
  // min-width: 300px !important;
}

.Application_DataGrid {
  .dx-datagrid-header-panel {
    border-bottom: none;
    background: $base-light-accent;
  }

  .dx-datagrid .dx-header-filter-empty {
    color: $base-white;
  }

  .dx-toolbar {
    background-color: $base-light-accent;
  }

  .dx-datagrid-search-panel {
    margin-left: 8px;
  }

  .dx-header-row {
    background-color: $base-accent !important;
    color: $base-white;

  }

  .dx-datagrid-borders>.dx-datagrid-headers,
  .dx-datagrid-borders>.dx-datagrid-rowsview,
  .dx-datagrid-borders>.dx-datagrid-total-footer {
    border-radius: 0px 0px 6px 6px;
  }

  .dx-datagrid-headers {
    border-radius: 6px 6px 0px 0px !important;

    .dx-datagrid-content {
      border-radius: 6px 6px 0px 0px !important;
    }
  }

  .dx-datagrid-table {
    border-collapse: unset !important;
    border-spacing: 0;
    margin: 0;
  }

  /* start Set a consistent row height for the DataGrid to avoid shaking effect */
  .dx-datagrid-rowsview .dx-row {
     height: 70px !important; 
     white-space: nowrap !important;
     overflow: hidden !important;
  }
  /* end Set a consistent row height for the DataGrid to avoid shaking effect */
}

.Application_DataGrid .dx-datagrid {
  border-radius: 8px 8px 8px 8px !important;
}

.add-new-candidate-btn {
  background: $base-light-mint-green !important;
  border-radius: 8px !important;
  font-weight: 500;

  .dx-button-content {
    padding: 6px;
  }
}

.search-candidate-btn {
  background: $base-soft-blue !important;
  border-radius: 8px !important;
  font-weight: 500;

  .dx-button-content {
    padding: 6px;
  }
}

.blue-text {
  color: $base-blue;
}

.add-interaction-btn {
  border-radius: 50%;
  border: 1px solid $base-blue !important;

  // text-decoration: underline;
  .dx-button-content {
    padding: 2px !important;
  }

  .dx-icon {
    color: $base-blue;
    font-size: 14px;
  }
}

.create-interaction {
  color: $base-blue;
  text-decoration: underline;

  cursor: pointer;

  .event-btn {
    margin-bottom: 4px !important;
    border-radius: 50%;

    // text-decoration: underline;
    .dx-button-content {
      padding: 0px !important;
    }

    .dx-icon {
      color: $base-blue;
    }
  }
}

.border_right {
  border-right: 1px solid $base-gray-gainsboro !important;
}

.join-meeting-section {
  color: $base-blue !important;
  text-decoration: none;

  .join-meeting {
    margin-left: 8px;
    font-style: italic;
    text-decoration: underline;
  }
}

.text-gray {
  color: $base-gray;
}

.text-link {
  @extend .font-medium-12;
  color: $base-dark-blue;
  text-decoration: underline;

}

.search-candidate-bg {
  background: $base-blue !important;
}

.SearchCandidate_Datagrid {
  .dx-toolbar {
    .dx-toolbar-after {
      margin-right: 10px !important;
      margin-top: 5px;
    }
  }
}

.of-experience {
  @extend .font-regular-12;
  color: $base-gray;
  font-style: italic;
}

.pipe-shortlisted-edit {
  color: $base-gray;
  font-weight: 100;
}

.first-col-job-opening-list {
  min-width: 300px;
}

.job-opening-list-section {
  min-width: 1600px !important;
}

.jobOpening_IsData {

  .dx-scrollable-both .dx-scrollable-content,
  .dx-scrollable-horizontal .dx-scrollable-content {
    min-width: 1600px !important;
  }

}

.Job_Opening_List {
  padding: 0px 5px !important;
  margin: 0px;

  .dx-datagrid-search-panel {
    margin-top: 5px;
  }

  .dx-datagrid-table {
    border-collapse: unset !important;
    border-spacing: 0;
    margin: 0;
  }

  .dx-scrollbar-vertical {
    margin-left: 5px !important;
  }
}

.job-opening-list-section {
  max-height: 500px !important;
}

.job-opening-section-height {
  height: calc(100vh - 60px);
}

.job-opening-section-height-sm {
  // height: calc(100vh - 140px) !important;
}

.job-opening-datagrid {
  height: calc(100vh - 220px) !important;
}

.job-opening-datagrid-md {
  height: calc(100vh - 350px) !important;
}

.job-opening-datagrid-sm {
  height: calc(100vh - 350px) !important;
}

.pipe-view-resume-rating {
  font-weight: 100;
}

.Search_Candidate_List {
  padding: 0px 5px !important;
  margin: 0px;

  .dx-datagrid-search-panel {
    margin-top: 5px;
  }

  // .dx-scrollable-both .dx-scrollable-content, .dx-scrollable-horizontal .dx-scrollable-content {
  //   min-width: 1600px !important;
  // }
  // .dx-datagrid-table {
  //   border-collapse: unset !important;
  //   border-spacing: 0;
  //   margin: 0;
  // }
}

.search-candidate-reset-layout {
  .dx-button-content {
    padding: 6px;
  }
}

.profile-image {
  border-radius: 100%;
}

.search-candidate-popup {
  min-width: 300px !important;
}

.Word_Wrap_Coloumn_Data {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space:  ;
}

.PublicPrivateIcon {
  width: 20px !important;
}

.JobOpeningSearchPage {
  .dx-datagrid-search-panel {
    margin-left: 0 !important;
  }
}

.JobOpeningSearchPageData {
  padding-left: 5px !important;
  padding-right: 9px !important;
}

.dx-assesment-custom-icon-lg {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  background-size: contain !important;
  background-position: 0 0;
  padding: 0;
  font-size: 22px;
  text-align: center;
  line-height: 22px;
}

.dx-assesment-custom-icon-skype-lg {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background-size: contain !important;
  background-position: 0 0;
  padding: 0;
  font-size: 20px;
  text-align: center;
  line-height: 20px;
}

.DownloadDropdownButton {
  background-color: #edf4fb;
  color: #01305f;
  font-weight: 500;
  padding: 0px !important;

  .dx-button-content {
    padding: 5px 8px 5px 8px !important;
  }

  //   .dx-button{
  //     background-color: rgb(218, 216, 216) !important;

  //   }
  //   .dx-button-text{
  //     text-overflow: clip !important;
  //     white-space: initial !important;
  //     overflow: visible;
  //   border-radius: 100px !important;

  //   }
  //   .dx-button-content {
  //   //  padding: 8px 8px 8px 8px !important;
  //   border-radius: 100px !important;

  //  }
  //  .dx-button-has-icon{
  //   padding: 0px 30px 0px 8px !important;
  //  }
  //  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
  //   //  border-radius: 0px 100px 100px 0px !important;
  //    background-color: rgb(193, 193, 245) !important;
  // }
}

// .GridRowDropdown{
//   .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
//     border: none !important;
//   }
//   .dx-button-content {
//     // padding: 4px 2px 4px 8px!important;
//   }
// }

// .DropdownButtonOrange{
//   .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
//     margin-left: 2px !important;
//     background-color: rgb(218, 216, 216) !important;
//     border: none !important;
// }
// }

.action-btn {
  // background: $base-light-mint-green !important;
  // background: $base-soft-blue !important;
  background-color: #edf4fb;
  border-radius: 8px !important;
  font-weight: 500;
  width: fit-content;

  .dx-button-content {
    padding: 6px;
  }
}

.button-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  height:100%;
}

.single-button {

  // flex: 0 0 100%; /* If fewer than 10 buttons, take full width */
  .dx-button-text {
    color: #01305f;
    text-overflow: clip !important;
    white-space: initial !important;
    overflow: visible;

  }
}

.double-button {
  .dx-button-text {
    color: #01305f;
    text-overflow: clip !important;
    white-space: initial !important;
    overflow: visible;

  }
}

@media (max-width: 576px) {
  .double-button {
    flex: 0 0 100%;
    /* On small screens, each button will take full width */
  }

  .single-button {
    flex: 0 0 100%;
    /* On small screens, each button will take full width */
  }
}

.candidate-details-tab {
  height: 540px;
}

#ProjectAttachmentGrid .dx-drawer-panel-content {
  display: none;
}

.dx-filemanager .dx-filemanager-container .dx-drawer-panel-content {
  display: none !important;
}

.dx-widget.dx-filemanager-breadcrumbs {
  display: none;
}

.list-section {
  height: "200px";
}

.header-title.frequency-title {
  font-size: 15px;
}

.job-opening-public-section {

  @media (min-width: 768px) {
    // background-color: $base-secondary-accent;
    background-color: #e7f0f7;
    padding: 16px 32px;
    height: 100vh !important;
  }

  .job-opening-public-card {
    background-color: $base-white;
    box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.056), 0 1px 2px rgba($base-black, alpha($base-black) * 0.09);
    border-radius: 4px;
  }

  .job-opening-public-content {
    height: calc(100vh - 30px) !important;
    .job-opening-grid {
      height: calc(100vh - 240px) !important;
      padding: 0px 5px !important;
      margin: 0px;
  
      .dx-datagrid-table {
        border-collapse: unset !important;
        border-spacing: 0;
        margin: 0;
      }
  
      .dx-scrollbar-vertical {
        margin-left: 5px !important;
      }
    }
  }

  .row-box {
    box-shadow: 0 2px 4px #e5e5e5;
    background-color: #fafafa;
    border-radius: 10px;
    margin: 3px 8px;
    padding: 10px;
    min-height: 120px;
  }

  .heading-horizontal-line {
    width: 140px;
    border: 1px solid $base-accent;
  }

  .current-openings-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    max-height: calc(2em * 3);
  }

}

.right-candidate-section-expand {
  width: 100%;
  border-left: 1px solid $base-silver;
  transition: all .2s;
  z-index: 0;
}

.expand-candidate-collapse-btn-right-section {

  .collapse-btn-right {
    left: 0;
    position: absolute;
    z-index: 1000;
    margin-top: 10px;
    background-color: $base-white;

    .dx-button-content {
      padding: 6px 4px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }

  .expand-btn-right {
    background-color: $base-white;

    .dx-button-content {
      padding: 6px 4px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
}

.expand-candidate-collapse-btn-right-section-sm {
  .collapse-btn-right {
    right: 6px;
    position: absolute;
    z-index: 1;
    margin-top: 5px;
    background-color: $base-white;

    .dx-button-content {
      padding: 6px 4px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 8px;
}

.chip {
  display: inline-block;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 500;
  color: $base-black;
  border-radius: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.1);
}

.chip-1 {
  background-color: #d8f3dc;
}

.chip-2 {
  background-color: #fde0be;
}

.chip-3 {
  background-color: #c1e4ff;
}

.chip-4 {
  background-color: #fff3bf;
}

.chip-5 {
  background-color: #e4dcf9;
}

.Address-Secttion {
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}