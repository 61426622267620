@import '../../dx-styles.scss';
@import '../../utils/custom-additional-variables.scss';


.candidate_progress_circle_in_progress {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: $base-white;
    background-color: $base-dark-yellow;
}

.candidate_progress_circle_pending {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    color: $base-white;
    background-color: $base-silver-gray;
}

.candidate_progress_line_completed {
    height: 4px;
    width: 30px;
    background-color: $base-lime-green;
}

.candidate_progress_line_pending {
    height: 4px;
    width: 30px;
    background-color: $base-silver-gray;
}

.step {
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: cream;
}

.v-stepper {
    position: relative;
}


/* regular step */
.step .circle {
    background-color: white;
    border: 3px solid gray;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.step .line {
    top: 20px;
    left: 8px;
    height: 100%;
    position: absolute;
    border-left: 3px solid gray;
}

.step.completed .circle {
    visibility: visible;
    background-color: $base-lime-green;
    border-color: $base-lime-green;
}

.step.completed .line {
    border-left: 3px solid $base-lime-green;
}

.step.pending .circle {
    visibility: visible;
    background-color: $base-silver-gray;
    border-color: $base-silver-gray;
}

.step.pending .line {
    border-left: 3px solid $base-silver-gray;
}

.step.in_progress .circle {
    visibility: visible;
    background-color: $base-dark-yellow;
    border-color: $base-dark-yellow;
}

.step.in_progress .line {
    border-left: 3px solid $base-silver-gray;
}

.step:last-child .line {
    border-left: 3px solid white;
    z-index: -1;
}