@import "../../themes/generated/variables.additional.scss";
@import '../../utils/custom-additional-variables.scss';

.Left-column-grid {
    width: 96% !important;
    margin-right: 15px !important;
}

.Current-Opening-Card-1{
    border-left: 4px solid $base-border-grey !important;
}

.Current-Opening-Card-2{
    border-left: 4px solid $base-border-green !important;
}

.Current-Opening-Card-3{
    border-left: 4px solid $base-border-pink !important;
}

.Current-Opening-Card-4{
    border-left: 4px solid $base-dark-blue !important;
}

.Current-Opening-Card-5{
    border-left: 4px solid $base-golden-yellow!important;
}
.card-Container{
    background-color: $base-pale-silver !important;
    border: 1px solid $base-light-teal !important;
}

.Scehduled-card {
    width: 400px !important;
}

.Selected-Card{
    border: 2px solid $base-border-teal !important;
    border-left: 4px solid $base-border-teal !important;
}

.CurrentOpening-Grid {
    .dx-datagrid {
        background-color:  $base-pale-silver !important;
        border: 1px solid #ddd !important;
        border-radius: 5px !important;
    }

    .dx-datagrid-header-panel .dx-toolbar{
        margin-bottom: 10px !important;
        padding: 7px  15px  0px 1rem!important;
        width: 96% !important;
        padding-right: 0px !important;
    }
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td{
        background-color: transparent !important;
    }
    .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td, .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td{
        border: 0px !important;
    }
    .dx-datagrid-search-panel{
        margin-left: 0px !important;
    }
    td {
        width: 100% !important;
    }
    .dx-toolbar{
        background-color:  $base-pale-silver !important;
    }
    .dx-datagrid-borders .dx-datagrid-rowsview {
        border-top: 0 !important;
    }
}

.Schedules_DataGrid {
    .dx-datagrid-header-panel {
      border-bottom: none;
      background: $base-light-accent;
    }
  
    .dx-datagrid .dx-header-filter-empty {
      color: $base-white;
    }
  
    .dx-toolbar {
      background-color: $base-light-accent;
    }
  
    .dx-datagrid-search-panel {
      margin-left: 0px;
    }
    .dx-header-row {
      background-color: $base-accent !important;
      color: $base-white;
  
    }
  
    .dx-datagrid-borders>.dx-datagrid-headers,
    .dx-datagrid-borders>.dx-datagrid-rowsview,
    .dx-datagrid-borders>.dx-datagrid-total-footer {
      border-radius: 0px 0px 6px 6px;
    }
  
    .dx-datagrid-headers {
      border-radius: 6px 6px 0px 0px !important;
  
      .dx-datagrid-content {
        border-radius: 6px 6px 0px 0px !important;
      }
    }
    .dx-button-mode-outlined.dx-button-default{
        border-color: #ddd !important;
    }
    .dx-button-mode-contained{
        background-color: transparent !important;
       .dx-icon {
        color: #327785 !important;
       }
    }
  }

.Schedule-Box {
    background-color: $base-bg-light !important;
}

.custom-card-grid {
    box-shadow: 0 0.125rem 0.25rem rgba($base-black, alpha($base-black) * 0.075) !important;
    border-radius: 10px;
    border: 1px solid $base-light-accent;
    background-color: $base-pale-silver !important;
  }

.join-meeting {
    margin-left: 8px;
    font-style: italic;
    text-decoration: underline;
}

.Current-Openings-Grid {
    height: auto !important;
    max-height: 743px !important;
}

.CurrentOpenningEmptyGrid{
    height: 743px !important;
}

.Upcoming-Schedule-Grid{
    height: 675px !important;
}

.Upcoming-Schedule-Grid-full{
    height: 363px !important;
}

.Schedule-Grid-column-chooser{
    .dx-button-mode-contained{
        color: $base-border-teal !important;
        background-color: transparent !important;
    }
    .dx-button-mode-contained .dx-icon{
        color: $base-border-teal !important;
    }
}

.CurrentOpening-Card-container{
    padding-bottom: 13px !important;
}