.custom-skeleton {
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    height: 30px;
    margin: 8px 0px 8px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
  }
  .list-toolbar-skeleton{
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 440px;
    height: 30px;
    margin: 0px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
  }
.skeleton-section{
  padding: 6px;
}
  .skeleton-rows{
    height: 20px;
    width: 100px;
  }
.skeleton-header-height{
    height: 50px;
    margin: 6px 0px;
  }
  
.skeleton-toolbar-height{
    height: 40px;
}
  @keyframes skeleton-animation {
    0% {
      background-position: -100px 0;
    }
    100% {
      background-position: calc(100px + 100%) 0;
    }
  }

  .custom-skeleton-card {
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    margin: 6px 0px 6px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
  }
  @keyframes skeleton-animation {
    0% {
      background-position: -100px 0;
    }
    100% {
      background-position: calc(100px + 100%) 0;
    }
  }

  .custom-skeleton-button {
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 110px;
    height: 35px;
    margin: 6px 0px 6px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
  }
  @keyframes skeleton-animation {
    0% {
      background-position: -100px 0;
    }
    100% {
      background-position: calc(100px + 100%) 0;
    }
  }