$base-danger:rgb(223, 63, 63);
$base-light-accent: #f4f6f6;
$base-light-gray: #d5d5d5;
$base-gray: #9c9c9c;
$base-blue: #2267b2; 
$base-dark-blue:rgb(34, 64, 124);
$base-dark-yellow: #f6c259;
$base-light-yellow: #f0ea94;
$base-white: #ffffff;
$base-picture-bg: rgba(183, 183, 183, 0.1);
$base-secondary-accent:#39879833;
$base-navy-blue:#20386d;
$base-black:#000000;
$base-light-teal: #6dbfcc;
$base-green: green;
$base-dark-accent: #185a6c;
$base-white:#fff;
$secondary-light-gray:rgb(230, 230, 230);
$base-success:rgb(20, 181, 20);
$base-lime-green: #81B622;
$base-muted-gray: rgb(221, 221, 221);
$base-deep-forest-green: rgb(42, 101, 6);
$base-soft-pink: rgb(255, 205, 203);
$base-soft-blue: rgb(203, 230, 255);
$base-deep-purple: rgb(93, 48, 157);
$base-steel-blue: rgb(60, 115, 159);
$base-very-light-gray: rgb(236, 235, 235);
$base-pale-white-gray: rgb(240, 240, 240);
$base-light-mint-green: rgb(214, 238, 214);
$base-pale-mint-green: rgb(178, 233, 187);
$base-gray-gainsboro: rgb(222, 226, 230);
$base-light-pink: rgb(255, 214, 214);
$base-silver: rgb(217, 217, 217);
$base-pale-silver: rgb(244, 246, 246);
$base-silver-gray: rgb(212, 208, 208);
$base-medium-gray: rgb(117, 117, 117);
$base-secondary-medium-gray: rgb(127, 127, 127);
$base-secondary-gray: rgb(146, 146, 146);
$base-pale-blue: rgb(252, 254, 255);
$base-slate-blue: rgb(55,150,189);
$base-golden-yellow: rgb(232, 174, 0);
$base-light-gray-blue: rgb(243, 247, 249);
$base-pale-gray-blue: rgb(231, 234, 236);
$base-pale-aqa: rgb(230, 242, 242);
$base-pale-gray: rgb(226, 224, 224);
$base-light-sky-blue: rgb(235, 243, 248);
$base-bg-light:#f9f9f9;
$base-border-pink: #d21564;
$base-border-teal:#2185a1;
$base-border-green : #7da71b;
$base-border-grey: #7f7f7f;
$base-border-lightred:#af5454;


:export{
    shortListedGaugeColor: #00AB66;
    underProcessGaugeColor: #6ba292;
    hiredGaugeColor: #ffdc7c;
    onBoardingGaugeColor: #2267b2;
    rejectedGaugeColor: #dd614a;
    dropoutGaugeColor: #ff9b71;
}