@import '../../../utils/custom-additional-variables.scss';


.company-title {
    color: $base-dark-accent;
}

.Company_Profile_container {
    .row{
    --bs-gutter-x: 0 !important; 
    --bs-gutter-y: 0 !important;
    }

    .screen-small{
        width: 80%;
    }

    .screen-x-small & {
        width: 90%;
    }

    .screen-xx-small & {
        width: 90%;
    }

    .screen-ex-small & {
        width: 90%;
    }
}
.secoundColumn{
    background-color: $base-light-teal;
}
.image-Skeleton{
    height: 200px;
    width: 200px;
}
.Company-Skeleton{
    height: 30px;
    width: 230px;
}