@import "../../../utils/custom-additional-variables.scss";

.JobProfilelist-section{
   height: 320px !important;
}
.HTMLEditor_scetion{
   padding-top: 14px !important;
}
.vertical-tabs .dx-tab {
   display: block;
   width: 100%;
   text-align: left;
}

.vertical-tabs .dx-tab-selected {
   background-color: $base-pale-white-gray;
}

.vertical-tabs .dx-tab-text {
   display: block;
}
.image-Skeleton-HTMLEditor{
   height: 397px !important;
   width: 100%;
}
.List-Height{
   height: 305px !important;
}

.interaction-add-button {
   color: #fff !important;
   border-color: #fff !important;
   background-color: #fff !important;

   .dx-button-content {
      padding: 2px;
   }
}

.text-ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

/* Splitters styling start */
.popup-content {
   display: flex;
   width: 100%;
   height: 500px;
   position: absolute;
}

.pane {
   height: 100%;
}

.pane-left {
   width: 30%;
   min-width: 40%;
   max-width: 65%;
   padding-left: 8px;
   padding-right: 5px;
   overflow: hidden;
}

.pane-right {
   flex-grow: 1;
   min-width: 35%;
   overflow: hidden;
}

.resizer {
   width: 0px;
   background: transparent;
   border-left: 2px dotted #ccc;
   margin: 0 1px;
   cursor: ew-resize;
   position: relative;
   z-index: 100;
}

.resizer:hover {
   background-color: transparent;
   cursor: ew-resize;
}

.pane-content {
   height: 100%;
   overflow: auto;
}

.disable-selection {
   user-select: none;
   /* Prevents text selection */
}

body.disable-selection {
   cursor: ew-resize;
   /* Show resize cursor */
}
/* Splitters styling end */

.Interaction-Round-HTML-Editor .dx-toolbar{
   overflow-x: hidden !important ;
}

.resizer {
   will-change: transform !important;
 }