.card-section {
    height: 97%;
    overflow-y: hidden;
    .card-skeleton {
        background-color: #e7eaec;
        background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
        background-size: 100px 100%;
        background-repeat: no-repeat;
        display: inline-block;
        line-height: 1;
        width: 100%;
        height: 120px;
        border-radius: 10px;
        margin: 3px 0px;
        padding: 10px;
        animation: skeleton-animation 1.5s ease-in-out infinite;
    }
}