@import '../../themes/generated/variables.base.scss';
@import '../../dx-styles.scss';

.add-detail-question-mark {
   .dx-button-content {
      padding: 1px 7px !important;
      font-weight: 600;

   }
}

.tooltip-content {
   white-space: normal;
   max-width: 300px;
   word-wrap: break-word;
}

.or-text {
   @extend .font-bold-16;
   @import '../../utils/custom-additional-variables.scss';
   color: $base-gray;
}

.quick-upload-resume {
   .dx-fileuploader-files-container {
      padding-top: 0px !important;
   }
}

.quick-upload-clear-btn {
   position: absolute;
   bottom: 2px;
   left: -20px;
}

.UploadResume_Box:hover {
   border-color: $base-accent !important;
}

.custom_loadpanel {
   z-index: 2000 !important;
}