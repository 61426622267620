@import "../../themes/generated/variables.base.scss";
@import '../../dx-styles.scss';
@import '../../utils/custom-additional-variables.scss';

.user-info {
  display: flex;
  align-items: center;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba($base-black, alpha($base-black) * 0.1);
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin: 0 4px;
    border: 1px solid rgba($base-black, alpha($base-black) * 0.1);
    box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.15);

    
  }

  .user-name {
    font-size: 14px;
    color: $base-text-color;
    margin: 0 9px;
  }
}

.user-panel {
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }
  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }
  .dx-menu-item .dx-menu-item-content {
      padding: 3px 15px 4px;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}

.UserPanel_PictureSection {
  width: 140px;

  #dropzone-external {
      width: 140px;
      height: 140px;
      border-radius: 4px;
      // background-color: $base-picture-bg;
      border-width: 1px;
      border-style: solid;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
 
  #dropzone-external>* {
      pointer-events: none;
  }

  #dropzone-external.dropzone-active {
      border-style: solid;
  }

  #dropzone-image {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      background-color: none !important;
      border-radius: 4px !important;
  }


  #dropzone-text {
      @extend .font-regular-13;
      
              
      span {
          font-weight: 100;
          //opacity: 0.5;
      }
  }

  #upload-progress {
      display: flex;
      margin-top: 10px;
  }

  .maxImageSizeSpan{
      font-size: 10px;
      color: $base-danger;
  }
  .maxImageSizeValidation{
      color: $base-danger;
  }
}

.grey-BgColor {
   background-color: $base-very-light-gray !important;
}
.image-Skeleton-userprofile{
  width: 140px;
  height: 140px;
}
.User-Skeleton{
  width: 160px;
  height: 30px;
}
.userImageContainer {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userImage_Profile {
  max-width: 100%;
  max-height: 100%;
}

.image-hover-section .profile-image{
  opacity: 1 ;
  transition: 0.5s ;
}
.image-hover-section:hover .profile-image{
  opacity: 0.3 !important;
}

.image-hover-section .content-on-image{
  opacity: 0;
  transition: 0.5s;
  z-index: 100;

  transform: translate(2%, 1.5%);
  .screen-small & {
      opacity: 1 !important;
    }
    .screen-ex-small & {
      opacity: 1 !important;
    }
    .screen-xx-small & {
      opacity: 1 !important;
    }
    .screen-x-small & {
      opacity: 1 !important;
    }
}

.image-hover-section:hover .content-on-image{
  opacity: 1 !important;
}


