@import "../../utils/custom-additional-variables.scss";
@import "../../dx-styles.scss";

.job-application-public-section {
    @media (min-width: 768px) {
        background-color: #e7f0f7;
        padding: 16px 32px;
        height: 100vh !important;
    }

    // height: 100vh !important;

    .job-application-public-card {
        background-color: $base-white;
        box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.056), 0 1px 2px rgba($base-black, alpha($base-black) * 0.09);
        border-radius: 4px;
    }

    .job-application-public-content {
        height: calc(100vh - 30px) !important;
        .application-detail-section {
            height: calc(100vh - 130px) !important;
            padding: 0px 5px !important;
            margin: 0px;
        }
    }

    .uploadResume {
        height: 55.5px;

        .dx-button {
            border-radius: 0 !important;
            border-left: 0 !important;
            border-bottom: 0 !important;
            border-top: 0 !important;
            padding-right: 10px !important;
            padding-top: 2px !important;
        }

        .dx-fileuploader-input-wrapper {
            padding: 5px 0px;
        }

        .dx-fileuploader-wrapper {
            width: 85px !important;
        }

        .dx-button-has-text .dx-button-content {
            padding: 8px;
        }

        .dx-fileuploader-wrapper {
            padding: 0 !important;
        }

        .dx-button-text {
            font-weight: 500 !important;
            font-size: 14px !important;
        }

        .spin_down_icon {
            .dx-icon-spindown {
                font-size: 25px !important;
            }
        }
    }

    .job-profile-caption {
        @extend .font-regular-18;
        color: $base-gray;
    }

    .floating-label {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $base-white;
        padding: 0 5px;
        pointer-events: none;
    }
}