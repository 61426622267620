@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss";
@import "../../dx-styles.scss";
@import "../../utils//custom-additional-variables.scss";

.ViewPopup{  
    .dx-popup-title {
        background-color: $base-light-accent;
        border-bottom: none;
        box-shadow: 0 1px 3px rgba($base-black, alpha($base-black) * 0.056), 0 1px 2px rgba($base-black, alpha($base-black) * 0.09);
    }
    .dx-popup-content {
        -webkit-user-drag: none;
        padding: 0px !important;
    }
    .view-title{
      @extend .font-semiBold-18;
      color: $base-accent;
       .dx-button-has-icon .dx-button-content{
        padding: 0px;
       }
       .Edit_Button {
            .dx-button-content{
                padding:2.5px !important;
                
              }
              .dx-icon{
                font-size: 17px !important; 
            }
       }
    .view-close-btn {
        .dx-button-has-icon .dx-icon{
        font-size: 14px;
        height: 14px;
        width: 14px;
    }
    .dx-button{
        border-radius: 24px !important;
      
    }
    .dx-button-has-icon .dx-button-content {
         padding: 4px;
    }
    .dx-button-has-icon .dx-button-content:hover {
        background-color: $base-white;
        border-radius: 24px;
    }
    }
}
  }

 
    
.base-accent-text{
    color: $base-accent !important;
}