@import "../../themes/generated/variables.base.scss";

.TechSkillDatagrid{
.dx-datagrid-rowsview .dx-row > .experience-column {  
    padding: 0px 0px 0px 10px;  
  } 
  .dx-header-row {
    background-color: $base-accent !important;
    color: $base-bg;
  
  }
}