@import '../../dx-styles.scss';
@import '../../utils/custom-additional-variables.scss';

.side-panel-height{
    // height: calc(100vh - 166px);
    height: 100%;
    z-index: 1;
}


.left-section-expand{
    width: 320px;
    border-right: 1px solid $base-silver;
    transition: all .2s;
}

.left-section-collapse {
    width: 0px;
    transition: all .2s;
  }
 

 

  .right-section-expand{
    width: 300px;
    border-left: 1px solid $base-silver;
    transition: all .2s;
    z-index: 0;
}

.right-section-collapse {
    width: 0px;
    transition: all .2s;
  }


  .filled-star{
    @extend .font-semiBold-23;
    color: $base-dark-yellow;
    
  }


.left-grid-section{
  height: calc(100vh - 250px);
}
.left-grid-section-small-screen{
  height: calc(100vh - 100px);
}

.right-grid-section{
  height: calc(100vh - 250px);
}
.right-grid-section-small-screen {
  height: calc(100vh - 100px);
}

.candidateDetailsGrid{
  .dx-datagrid-header-panel{
    padding: 0px 10px;
  }
}


.expand-collapse-btn-section-left{
  
  
  .collapse-btn-left{
    left: 314px;
    position: absolute;
    z-index: 1;
    margin-top: 5px;
    background-color: $base-white;
    .dx-button-content{
        padding: 6px 4px;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
  .expand-btn-left{
    position: absolute;
    margin-top: 5px;
    background-color: $base-white;
    z-index: 1000;
    .dx-button-content{
        padding: 6px 4px;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
}
.expand-collapse-btn-section-left-sm{
  .collapse-btn-left{
    // left: 320px;
    position: absolute;
    z-index: 1000;
    margin-top: 30px;
    background-color: $base-white;
    .dx-button-content{
        padding: 6px 4px;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
}

.expand-collapse-btn-reight-section{

  .collapse-btn-right{
    right: 286px;
    position: absolute;
    z-index: 1000;
    margin-top: 5px;
    background-color: $base-white;
    .dx-button-content{
      padding: 6px 4px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
  .expand-btn-right{
    position: absolute;
    right: 2px;
    // z-index: 1000;
    margin-top: 5px;
    background-color: $base-white;
    .dx-button-content{
      padding: 6px 4px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
}
.expand-collapse-btn-reight-section-sm{
  .collapse-btn-right{
    // right: 336px;
    right: 6px;
    position: absolute;
    z-index: 1;
    margin-top: 5px;
    background-color: $base-white;
    .dx-button-content{
      padding: 6px 4px;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba($base-secondary-medium-gray, alpha($base-secondary-medium-gray) * 0.439), 0 1px 3px rgba($base-medium-gray, alpha($base-medium-gray) * 0.337);
    }
  }
}

.timeline-arrowup-icon{
  font-size: 32px;
  color: $base-light-gray;
  font-weight: 100 !important;
}

.timeline-lable{
  background: $base-slate-blue;
  font-weight: 500;
  background: linear-gradient(90deg, rgba(72, 151, 182, 0.504) 0%, rgb(64, 128, 217) 100%); border: 1px solid $base-light-gray;
  color: $base-white;
  height: 32px;
  width: 32px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
}

.uploaded-by{
  @extend .font-regular-13;
  color: $base-gray;
}

.timeline-verticle-line{
  color: $base-light-gray;
}

.text-ellipsis{
  width: auto !important;
  max-width: 220px !important;
  text-overflow: ellipsis; 
  white-space: nowrap;
  overflow: hidden; 
}

.candidate-detail-section{
  border-bottom: 1px solid $base-pale-white-gray;
  .candidate-name {
    @extend .text-ellipsis;
    text-decoration: underline;
  }
}

.resume-iframe{
  height: calc(100vh - 350px);
  padding-bottom: 6px;
}

.resume-iframe-sm{
  height: calc(100vh - 440px);
}



.candidate-left-header-tab{
  // background-color: $base-light-accent;
  box-shadow: 0 1px 2px rgba($base-black, alpha($base-black) * 0.12), 0 1px 2px rgba($base-black, alpha($base-black) * 0.24) !important;
  margin: 0px 0px 12px 0px;
  @extend .font-semiBold-13;
  .dx-tab.dx-tab-selected {
    background-color: $base-pale-blue;
    border-bottom: 2px solid $base-accent;
  }
  .pending-count-rounded{
    height: 24px;
    width: 24px;
    background-color: rgba($base-golden-yellow, alpha($base-golden-yellow) * 0.35);
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-left: 4px;
  }
  .shortlisted-count-rounded{
    height: 24px;
    width: 24px;
    background-color: $base-pale-mint-green;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-left: 4px;
  }
  .rejected-count-rounded{
    height: 24px;
    width: 24px;
    background-color: $base-light-pink;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    margin-left: 4px;
  }
  .dx-tab{
    box-shadow: none !important;
    background-color: $base-light-accent;
    border-bottom: 2px solid transparent;
  }
}

.timeline-header{
  background-color: $base-light-accent;
  box-shadow: 0 1px 2px rgba($base-black, alpha($base-black) * 0.12), 0 1px 2px rgba($base-black, alpha($base-black) * 0.24) !important;
  margin: 0px 0px 12px 0px;
  padding: 10px 10px 10px 23px;
  @extend .font-semiBold-16;
}


.resume-edit-btn{
  
  border-radius: 100%;
  .dx-button-content{
    padding: 0px;
  }
  .dx-icon{
    color: $base-blue;
  }
}
.delete-edit-btn{
  border-radius: 100%;
  .dx-button-content{
    padding: 0px;
  }
}

#CandidateGrid tbody.dx-state-hover {
  background-color: $base-light-accent;
}

.add-new-candidate-btn {
  background: $base-light-mint-green !important;
  border-radius: 8px !important;
  font-weight: 500;

  .dx-button-content {
    padding: 6px;
  }
}

.search-candidate-btn {
  background: $base-soft-blue !important;
  border-radius: 8px !important;
  font-weight: 500;

  .dx-button-content {
    padding: 6px;
  }
}

.middleSection-large-screen{
  @media (max-height : 850px) {
  height: 75vh !important;
  }
  @media (max-height : 700px) {
    height: 70vh !important;
  }
}

.ShortlistedDetailSection{
  width: calc(100% - 140px) !important;
}

.ShortlistedCandidateNameSection{
  max-width: 500px !important;

  .screen-small & {
     max-width: 480px !important;
  }

  .screen-x-small & {
    max-width: 300px !important;
  }

  .screen-xx-small & {
    max-width: 230px !important;
  }

  .screen-ex-small & {
      max-width: 150px !important;
  }
}

.MoveStatus-Icons{
  margin-left: 2px !important;
}
.MoveStatusIco-Gap{
  margin-bottom: 4px !important;
}